import React, { useState, useEffect } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const SearchResultsImagePopup = (props) => {

    // Property details images lightbox
    var propertyImagePopup = []
    
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        setPhotoIndex(props.photoIndex)
    },[props.photoIndex])

    if(props.img_type === "no_property"  || props.img_type === "property_list" || props.img_type === "property_details"){
        props?.propertyImage?.length > 0 && props?.propertyImage.map(item => propertyImagePopup.push(item.url ? item.url : item.srcUrl))
        

        if(props.property_data?.floorplan?.length > 0){
            propertyImagePopup.push(props.property_data?.floorplan[0]?.url ? props.property_data.floorplan[0].url : props.property_data?.floorplan[0]?.srcUrl)
        }

        if(props.property_data?.epc?.length > 0){
            propertyImagePopup.push(props.property_data?.epc[0]?.url ? props.property_data?.epc[0]?.url : props.property_data?.epc[0]?.srcUrl)
        }
    } else{
        props?.propertyImage?.length > 0 && props?.propertyImage.map(item => propertyImagePopup.push(item[props.largeSize] ? item[props.largeSize] : item[props.largeSize2]))
    }
    
    //console.log("props_propertyImage", props.isOpen, props.img_type, propertyImagePopup)

    return (
        <React.Fragment>
            {props.isOpen && propertyImagePopup.length != 0 && (
                <Lightbox
                    wrapperClassName="light-box-image-zoom"
                    mainSrc={propertyImagePopup[photoIndex]}
                    nextSrc={
                        propertyImagePopup[
                            (photoIndex + 1) % propertyImagePopup.length
                        ]
                    }
                    prevSrc={
                        propertyImagePopup[
                            (photoIndex + propertyImagePopup.length - 1) %
                            propertyImagePopup.length
                        ]
                    }
                    onCloseRequest={() => props.setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + propertyImagePopup.length - 1) % propertyImagePopup.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyImagePopup.length)
                    }
                />
            )}
        </React.Fragment>
    )
}

export default SearchResultsImagePopup